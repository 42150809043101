import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import { useMove } from '@react-aria/interactions';
import { useModal, usePreventScroll } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import useSize from '@react-hook/size';
import { useWindowSize } from '@react-hook/window-size';
import { useOverlayTriggerState } from '@react-stately/overlays';
import * as React from 'react';
import { CSSProperties, forwardRef, LegacyRef, memo, RefObject, useRef } from 'react';

import { HeightVals, IPositionProps, OverflowVals, SpaceVals } from '../../enhancers';
import { useOverlay, useThemeIsDark } from '../../hooks';
import { Box } from '../Box';
import { Button } from '../Button';
import { Flex } from '../Flex';
import { Heading } from '../Heading';
import { Icon } from '../Icon';
import { Overlay } from '../Overlay';

export type ModalProps = {
  /**
   * The content to render in the Modal.
   */
  children: React.ReactNode;

  /**
   * The content to render in the footer slot.
   */
  footer?: React.ReactElement | string;

  /**
   * Whether the Modal is open.
   */
  isOpen: boolean;

  /**
   * Called when the Modal closes.
   */
  onClose: () => void;

  /**
   * When true the user will be able to interact with content behind the modal, and move the modal around.
   */
  isDraggable?: boolean;

  /**
   * Whether to prevent closing the overlay when the user interacts outside of it.
   */
  isNotDismissable?: boolean;

  size?: 'sm' | 'md' | 'lg' | 'full' | 'expand' | 'grow';

  zIndex?: IPositionProps['zIndex'];
} & ModalConditionalProps;

export type ModalConditionalProps =
  | {
      title?: React.ReactElement | string;
    }
  | {
      renderHeader: (props: {
        containerProps: ModalBoxProps['moveProps'];
        titleProps: ModalBoxProps['titleProps'];
        onClose?: ModalBoxProps['onClose'];
      }) => React.ReactElement;
    };

const modalSizeVariants: Record<ModalProps['size'], CSSProperties> = {
  sm: {
    width: '100%',
    maxWidth: 400,
  },
  md: {
    width: '100%',
    maxWidth: 600,
  },
  lg: {
    width: '100%',
    maxWidth: 900,
  },
  full: {
    width: '100%',
    maxWidth: '90%',
  },
  expand: {
    width: '100%',
  },
  grow: {
    maxWidth: '90%',
  },
};

export const useModalState = () => {
  const state = useOverlayTriggerState({});

  return {
    isOpen: state.isOpen,
    open: state.open,
    close: state.close,
  };
};

export const Modal: React.FC<ModalProps> = props => {
  const { children, footer, isOpen, onClose, isDraggable, isNotDismissable, ...rest } = props;

  return (
    <Overlay isOpen={isOpen}>
      <ModalWrapper
        {...rest}
        footer={footer}
        isOpen={isOpen}
        onClose={onClose}
        isDraggable={isDraggable}
        isNotDismissable={isNotDismissable}
      >
        {children}
      </ModalWrapper>
    </Overlay>
  );
};

type ModalWrapperProps = Pick<
  ModalProps,
  'children' | 'footer' | 'isOpen' | 'onClose' | 'isDraggable' | 'isNotDismissable'
> &
  ModalConditionalProps;

function isElementAllowedToBeInteractedOutside(element: HTMLElement) {
  const popoverParent = element.closest('[data-ispopover="true"]');
  return !!popoverParent;
}

// returns implementation of shouldCloseOnInteractOutside for making sure that clicking elements outside the modal
// but triggered by modal itself (like select, menu) will not close the modal
// see https://github.com/stoplightio/mosaic/issues/303 for details
function preventClosingForShortTime() {
  let shouldPrevent = false;
  return function shouldCloseOnInteractionOutside(element: HTMLElement) {
    if (isElementAllowedToBeInteractedOutside(element)) {
      shouldPrevent = true;
      setTimeout(() => {
        shouldPrevent = false;
      }, 400);
      return false;
    } else if (shouldPrevent) {
      return false;
    }
    return true;
  };
}

const ModalWrapper = ({
  children,
  footer,
  isOpen,
  onClose,
  isDraggable,
  isNotDismissable,
  ...props
}: ModalWrapperProps) => {
  const ref = useRef();

  const preventClosingFunction = React.useMemo(preventClosingForShortTime, [isOpen]);

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const { overlayProps } = useOverlay(
    {
      onClose,
      isOpen,
      isDismissable: !isDraggable && !isNotDismissable,
      shouldCloseOnInteractOutside: preventClosingFunction,
    },
    ref,
  );

  // Hide content outside the modal from screen readers.
  const { modalProps } = useModal();

  // Get props for the dialog
  const { dialogProps, titleProps } = useDialog({}, ref);

  // TODO: having to pull out color for the typings is so annoying...
  const { color, ...containerProps } = mergeProps(overlayProps, modalProps);
  const { color: c2, ...dialogPropsWithoutColor } = dialogProps;
  const { color: c3, ...dialogTitlePropsWithoutColor } = titleProps;

  if (isDraggable) {
    return (
      <DraggableModalBox
        {...props}
        ref={ref}
        onClose={onClose}
        containerProps={containerProps}
        dialogProps={dialogPropsWithoutColor}
        titleProps={dialogTitlePropsWithoutColor}
        footer={footer}
        isNotDismissable={isNotDismissable}
      >
        {children}
      </DraggableModalBox>
    );
  }

  return (
    <StaticModalBox
      {...props}
      ref={ref}
      onClose={onClose}
      containerProps={containerProps}
      dialogProps={dialogPropsWithoutColor}
      titleProps={dialogTitlePropsWithoutColor}
      footer={footer}
      isNotDismissable={isNotDismissable}
    >
      {children}
    </StaticModalBox>
  );
};

type ModalBoxProps = Pick<ModalProps, 'children' | 'footer' | 'onClose' | 'isDraggable' | 'size' | 'zIndex'> &
  ModalConditionalProps & {
    ref: LegacyRef<HTMLDivElement>;
    containerProps: Omit<React.HTMLAttributes<HTMLElement>, 'color'>;
    dialogProps: Omit<React.HTMLAttributes<HTMLElement>, 'color'>;
    titleProps: Omit<React.HTMLAttributes<HTMLElement>, 'color'>;
    moveProps?: Omit<React.HTMLAttributes<HTMLElement>, 'color'>;
    position?: { x: number; y: number };
    isHidden?: boolean;
    isNotDismissable?: boolean;
  };

const ModalBox = forwardRef(
  (
    {
      isDraggable,
      moveProps = {},
      containerProps,
      dialogProps,
      titleProps,
      onClose,
      children,
      footer,
      position,
      isHidden,
      isNotDismissable,
      size = 'md',
      zIndex,
      ...props
    }: ModalBoxProps,
    ref,
  ) => {
    const isDark = useThemeIsDark();

    const style: CSSProperties = Object.assign({}, modalSizeVariants[size] || {});
    if (position) {
      style.position = 'fixed';
      style.top = position.y;
      style.left = position.x;
    }

    if (size === 'full') {
      style.height = '100%';
      style.maxHeight = '90%';
    }

    if (size === 'expand') {
      style.height = '100%';
    }

    let headerElem;
    if ('renderHeader' in props && props.renderHeader) {
      headerElem = props.renderHeader({
        containerProps: moveProps,
        titleProps,
        onClose,
      });
    } else if ('title' in props && props.title) {
      headerElem = (
        <Flex
          {...moveProps}
          borderB
          borderColor={isDark ? 'input' : undefined}
          alignItems="center"
          pl={5}
          pr={3}
          cursor={!!position ? 'move' : undefined}
          h="3xl"
        >
          {typeof props.title === 'string' ? (
            <Heading size={3} fontSize="xl" flex={1} fontWeight="medium" {...titleProps}>
              {props.title}
            </Heading>
          ) : (
            <Box {...titleProps} flex={1} as="header">
              {props.title}
            </Box>
          )}

          {!isNotDismissable && (
            <Button
              appearance="minimal"
              icon={<Icon icon="times" size="2x" />}
              onPress={onClose}
              aria-label="dismiss"
            />
          )}
        </Flex>
      );
    }

    let footerElem;
    if (footer) {
      footerElem = (
        <Box borderT borderColor={isDark ? 'input' : undefined} alignItems="center" pl={5} pr={3} py={3}>
          {footer}
        </Box>
      );
    }

    return (
      <Box
        {...containerProps}
        bg="canvas-dialog"
        boxShadow="lg"
        rounded="lg"
        pos="relative"
        mx={4}
        style={mergeProps(style, { zIndex })}
        visibility={isHidden ? 'invisible' : undefined}
      >
        <FocusScope restoreFocus={!isDraggable} contain={!isDraggable}>
          <Flex
            {...dialogProps}
            ref={ref as LegacyRef<HTMLDivElement>}
            aria-describedby={`${dialogProps['aria-labelledby']}-body`}
            aria-modal="true"
            data-testid="modal"
            h={['full', 'expand'].includes(size) ? 'full' : undefined}
            flexDirection="col"
          >
            {headerElem}

            <ModalContent
              id={`${dialogProps['aria-labelledby']}-body`}
              p={headerElem || footerElem ? 5 : undefined}
              h={['full', 'expand'].includes(size) ? 'full' : undefined}
              overflowY={['full', 'expand'].includes(size) ? 'auto' : undefined}
            >
              {children}
            </ModalContent>

            {footerElem}
          </Flex>
        </FocusScope>
      </Box>
    );
  },
);

/**
 * Memo modal content so that it does not re-render constantly in draggable modals
 */
const ModalContent = memo(
  ({
    children,
    id,
    p,
    h,
    overflowY,
  }: {
    children: React.ReactNode;
    id: string;
    p?: SpaceVals;
    h?: HeightVals;
    overflowY?: OverflowVals;
  }) => {
    return (
      <Box p={p} id={id} h={h} overflowY={overflowY}>
        {children}
      </Box>
    );
  },
);

const StaticModalBox = forwardRef((props: ModalBoxProps, ref: RefObject<HTMLDivElement>) => {
  // Prevent scrolling while the modal is open
  usePreventScroll();

  return (
    <Flex pos="fixed" alignItems="center" justifyContent="center" pin overflowY="auto">
      <Underlay />
      <ModalBox {...props} ref={ref} />
    </Flex>
  );
});

const DraggableModalBox = forwardRef((props: ModalBoxProps, ref: RefObject<HTMLDivElement>) => {
  const [position, setPosition] = React.useState({
    x: 0,
    y: 0,
  });

  // get the width of the button trigger so that we can set the menu min width
  const [modalWidth, modalHeight] = useSize(ref);
  const [windowWidth, windowHeight] = useWindowSize();

  React.useEffect(() => {
    if (modalWidth && windowWidth) {
      const y = windowHeight / 2 - modalHeight / 2;
      setPosition({
        x: windowWidth / 2 - modalWidth / 2,
        y: y - y * 0.7,
      });
    }
  }, [modalWidth, windowWidth, setPosition, windowHeight, modalHeight]);

  const clamp = (pos, containerSize, componentSize) => Math.min(Math.max(pos, 0), containerSize - componentSize);

  const { moveProps } = useMove({
    onMove(e) {
      setPosition(({ x, y }) => {
        // Normally, we want to allow the user to continue
        // dragging outside the box such that they need to
        // drag back over the ball again before it moves.
        // This is handled below by clamping during render.
        // If using the keyboard, however, we need to clamp
        // here so that dragging outside the container and
        // then using the arrow keys works as expected.
        if (e.pointerType === 'keyboard') {
          // eslint-disable-next-line no-param-reassign
          x = clamp(x, windowWidth, modalWidth);
          // eslint-disable-next-line no-param-reassign
          y = clamp(y, windowHeight, modalHeight);
        }

        // eslint-disable-next-line no-param-reassign
        x += e.deltaX;
        // eslint-disable-next-line no-param-reassign
        y += e.deltaY;

        return {
          x: clamp(x, windowWidth + modalWidth * 0.5, modalWidth),
          y: clamp(y, windowHeight + 50, modalHeight),
        };
      });
    },
  });

  return <ModalBox {...props} moveProps={moveProps} position={position} ref={ref} isHidden={!modalWidth} />;
});

const Underlay = () => {
  return <Box pos="fixed" pin style={{ backgroundColor: 'rgba(0, 0 , 0, .3)' }} />;
};
